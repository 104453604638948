import React, { useEffect, useState } from 'react';
import {
  Table,
  Alert,
  Button,
  Input
} from 'rsuite';
import axios from 'axios';

import Page from '../../../components/Page';
import fileDownload from 'js-file-download';
import useDebounce from '../../../hooks/useDebounce';

const HouseWarming = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);

  const getArticles = () => {
    setLoading(true);

    axios.get(`/admin/boards/house-warmings?query=${debouncedQuery}`)
    .then(res => res.data)
    .then(res => {
      setArticles(res.houseWarmings);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    });
  };

  const toggleArticle = (id) => {
    axios.put(`/admin/boards/house-warmings/status/${id}`)
    .then(() => {
      getArticles();
    })
    .catch(err => {
      Alert.error("오류가 발생했습니다!");
    });
  };

  const exportToExcel = () => {
    axios(`/admin/boards/house-warmings/export`, {
      responseType: 'blob'
    })
    .then(res => {
      fileDownload(res.data, '[바네스데코] 집들이 게시글 리스트.xlsx');
    });
  };

  const remove = (id) => {
    if (window.confirm('삭제하시겠습니까? 삭제 후에는 복구가 불가합니다.')) {
      axios.delete(`/admin/boards/house-warmings/${id}`)
      .then(() => {
        Alert.info('게시글이 삭제되었습니다!');
        getArticles();
      })
      .catch(err => {
        Alert.error(err.response.data.message);
      })
    }
  };

  useEffect(() => {
    getArticles();
  }, [debouncedQuery]);

  return (
    <Page pageName="houseWarming">
      <h3>집들이 게시글 리스트</h3>
      <Input type="text" placeholder="검색어를 입력해주세요" style={{ width: '20%', marginTop: 8, marginBottom: 8 }} value={searchQuery} onChange={setSearchQuery} />
      <Button onClick={exportToExcel} appearance="primary">엑셀 다운로드</Button>
      <Table autoHeight data={articles} loading={loading} rowHeight={142}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>제목</Table.HeaderCell>
          <Table.Cell dataKey="title" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>조회수</Table.HeaderCell>
          <Table.Cell dataKey="viewCnt" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>등록일자</Table.HeaderCell>
          <Table.Cell dataKey="createdAt" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell>
          {
              (rowData) => (
                <>
                  <Button
                    appearance="primary"
                    onClick={() => {
                      window.open(`http://vanessdeco.com/community/houses/detail.html?id=${rowData.id}`)
                    }}
                  >
                    게시글 보기
                  </Button> <br />
                  <Button
                    style={{ marginTop: 8 }}
                    appearance="primary"
                    color={rowData.isActive ? 'red' : 'green'}
                    onClick={() => toggleArticle(rowData.id)}
                  >
                    { rowData.isActive ? '게시글 비활성화' : '게시글 승인' }
                  </Button> <br />
                  <Button
                    style={{ marginTop: 8 }}
                    appearance="default"
                    color="red"
                    onClick={() => remove(rowData.id)}
                  >
                    삭제
                  </Button>
                </>
              )
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </Page>
  );
};

export default HouseWarming;