import React, { useEffect, useState } from 'react';
import {
  Table,
  Alert,
  Input,
  Button
} from 'rsuite';
import dayjs from 'dayjs';
import axios from 'axios';

import useDebounce from '../../hooks/useDebounce';
import Page from '../../components/Page';
import fileDownload from 'js-file-download';

const User = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);

  const excelExport = () => {
    axios('/admin/users/export', {
      responseType: 'blob'
    })
    .then(res => {
      fileDownload(res.data, '[바네스데코] 사용자 리스트.xlsx');
    });
  };

  useEffect(() => {
    axios.get(`/admin/users?query=${debouncedQuery}`)
    .then(res => res.data)
    .then(res => {
      setUsers(res.users);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    })
  }, [debouncedQuery]);

  return (
    <Page pageName="user">
      <h3>사용자 리스트</h3>
      <Input type="text" placeholder="검색어를 입력해주세요" style={{ width: '20%', marginTop: 8, marginBottom: 8 }} value={searchQuery} onChange={setSearchQuery} />
      <Button color="green" onClick={excelExport}>엑셀 다운로드</Button>
      <Table autoHeight data={users} loading={loading}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>아이디</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>닉네임</Table.HeaderCell>
          <Table.Cell dataKey="nickname" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>한줄 소개</Table.HeaderCell>
          <Table.Cell dataKey="comment" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>개인 URL</Table.HeaderCell>
          <Table.Cell dataKey="url" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>광고 수신 동의 여부</Table.HeaderCell>
          <Table.Cell>
            {
              (rowData) => rowData.adAgree ? "동의" : "거부"
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>가입일자</Table.HeaderCell>
          <Table.Cell>
            {
              (rowData) => dayjs(rowData.createdAt).format('YYYY년 MM월 DD일')
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </Page>
  );
};

export default User;
        