import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Alert,
  Button,
  Input,
  Icon
} from 'rsuite';
import axios from 'axios';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Page from '../../../components/Page';
import fileDownload from 'js-file-download';
import useDebounce from '../../../hooks/useDebounce';

const DraggableCell = ({ children, onDrag, id, rowData, ...rest }) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'row',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop(item, monitor) {
      onDrag && onDrag(item.id, rowData.id);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { id: rowData.id, type: 'row' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  return (
    <Table.Cell {...rest} style={{ padding: '1rem', cursor: 'move' }}>
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, background: isActive ? "#ddd" : null }}>
        {children}
      </div>
    </Table.Cell>
  )
};

const Knowhow = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);

  const getArticles = () => {
    axios.get(`/admin/boards/knowhows?query=${debouncedQuery}&sort=home`)
    .then(res => res.data)
    .then(res => {
      setArticles(res.knowhows);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    });
  };

  const toggleArticle = (id) => {
    axios.put(`/admin/boards/knowhows/status/${id}`)
    .then(() => {
      getArticles();
    })
    .catch(err => {
      Alert.error("오류가 발생했습니다!");
    });
  };

  const remove = (id) => {
    if (window.confirm('삭제하시겠습니까? 삭제 후에는 복구가 불가합니다.')) {
      axios.delete(`/admin/boards/knowhows/${id}`)
      .then(() => {
        Alert.info('게시글이 삭제되었습니다!');
        getArticles();
      })
      .catch(err => {
        Alert.error(err.response.data.message);
      })
    }
  };

  const exportToExcel = () => {
    axios(`/admin/boards/knowhows/export`, {
      responseType: 'blob'
    })
    .then(res => {
      fileDownload(res.data, '[바네스데코] 노하우 게시글 리스트.xlsx');
    });
  };

  const sort = async (source, sourceId, targetId) => {
    const nextData = source.filter((item) => item.id !== sourceId);
    const dragItem = source.find((item) => item.id === sourceId);
    const index = nextData.findIndex((item) => item.id === targetId);
  
    nextData.splice(index + 1, 0 ,dragItem);
    await changeSort(sourceId, targetId);
  
    return nextData;
  };
  
  const changeSort = async (source, target) => {
    setLoading(true);

    await axios({
      method: 'PUT',
      url: '/admin/boards/knowhows/homeSort',
      data: {
        target,
        source
      }
    });

    getArticles();
  }

  const handleDragRow = (sourceId, targetId) => {
    sort(articles, sourceId, targetId);
  };

  useEffect(() => {
    getArticles();
  }, [debouncedQuery]);

  return (
    <Page pageName="knowhowHomeSort">
      <h3>노하우 게시글 메인 정렬 관리</h3>
      <Button onClick={exportToExcel} appearance="primary">엑셀 다운로드</Button>
      <Input type="text" placeholder="검색어를 입력해주세요" style={{ width: '20%', marginTop: 8, marginBottom: 8 }} value={searchQuery} onChange={setSearchQuery} />
      <DndProvider backend={HTML5Backend}>
        <Table autoHeight data={articles} loading={loading} rowHeight={142}>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.Cell dataKey="title" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>조회수</Table.HeaderCell>
            <Table.Cell dataKey="viewCnt" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>등록일자</Table.HeaderCell>
            <Table.Cell dataKey="createdAt" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.Cell>
            {
                (rowData) => (
                  <>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        window.open(`http://vanessdeco.com/community/knowhow/detail.html?id=${rowData.id}`)
                      }}
                    >
                      게시글 보기
                    </Button> <br />
                    <Button
                      style={{ marginTop: 8 }}
                      appearance="primary"
                      color={rowData.isActive ? 'red' : 'green'}
                      onClick={() => toggleArticle(rowData.id)}
                    >
                      { rowData.isActive ? '게시글 비활성화' : '게시글 승인' }
                    </Button> <br />
                    <Button
                      style={{ marginTop: 8 }}
                      appearance="default"
                      color="red"
                      onClick={() => remove(rowData.id)}
                    >
                      삭제
                    </Button>
                  </>
                )
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>정렬</Table.HeaderCell>
            <DraggableCell onDrag={handleDragRow}>
              <Icon icon="arrows"/>
            </DraggableCell>
          </Table.Column>
        </Table>
      </DndProvider>
    </Page>
  );
};

export default Knowhow;