import React, { useEffect, useState } from 'react';
import {
  Table,
  Alert,
  Button
} from 'rsuite';
import axios from 'axios';

import Page from '../../components/Page';
import dayjs from 'dayjs';

const User = () => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    axios.get('/admin/reports')
    .then(res => res.data)
    .then(res => {
      setReports(res.reports);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    })
  }, []);

  const ignoreReport = (id) => {
    if (window.confirm("신고 대상 게시글이 아닌, 신고를 삭제합니다. 삭제하시겠습니까?")) {
      axios({
        method: "POST",
        url: "/admin/reports/ignore",
        data: { 
          id
         }
      }).then(() => {
        alert('신고가 삭제되었습니다!');
        axios.get('/admin/reports')
        .then(res => res.data)
        .then(res => {
          setReports(res.reports);
          setLoading(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        });
      });
    }
  };

  const processReport = (id) => {
    if (window.confirm("신고 대상 게시글이 삭제처리됩니다. 삭제하시겠습니까?")) {
      axios({
        method: "POST",
        url: "/admin/reports/process",
        data: { 
          id
         }
      }).then(() => {
        alert('신고가 처리되었습니다!!');
        axios.get('/admin/reports')
        .then(res => res.data)
        .then(res => {
          setReports(res.reports);
          setLoading(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        });
      });
    }
  };

  const categoryToKorean = (category) => {
    switch (category) {
      case 'houseWarming':
        return '집들이';
      case 'picture':
        return '사진';
      case 'life':
        return '라이프';
      default:
        return '노하우';
    }
  };

  const categoryToUrl = (category) => {
    switch (category) {
      case 'houseWarming':
        return 'houses';
      case 'picture':
        return 'photo';
      default:
        return category;
    }
  };

  return (
    <Page pageName="report">
      <h3>신고 리스트</h3>
      <Table autoHeight data={reports} loading={loading} rowHeight={64}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>게시판 카테고리</Table.HeaderCell>
          <Table.Cell>
            {
              (rowData) => categoryToKorean(rowData.targetCategory)
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>신고 내용</Table.HeaderCell>
          <Table.Cell dataKey="contents" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>신고자</Table.HeaderCell>
          <Table.Cell dataKey="userId" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>신고 접수 일자</Table.HeaderCell>
          <Table.Cell>
            {
              (rowData) => dayjs(rowData.createdAt).format('YYYY년 MM월 DD일')
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell>
          {
              (rowData) => (
                <>
                  <Button
                    onClick={() => {
                      window.open(`http://vanessdeco.com/community/${categoryToUrl(rowData.targetCategory)}/detail.html?id=${rowData.targetId}`)
                    }}
                    appearance="primary"
                  >
                    게시글 보기
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    appearance="default"
                    onClick={() => ignoreReport(rowData.id)}
                  >
                    신고 삭제
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    appearance="default"
                    onClick={() => processReport(rowData.id)}
                    color="red"
                  >
                    게시글 삭제
                  </Button>
                </>
              )
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </Page>
  );
};

export default User;