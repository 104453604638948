import React, { useEffect, useState } from 'react';
import {
  Table,
  Alert,
  Button,
  Input
} from 'rsuite';
import axios from 'axios';
import useDebounce from '../../../hooks/useDebounce';

import Page from '../../../components/Page';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

const Picture = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);

  const getArticles = () => {
    axios.get(`/admin/boards/pictures?query=${debouncedQuery}`)
    .then(res => res.data)
    .then(res => {
      setArticles(res.pictures);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    });
  };

  const removeArticle = (id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios.delete(`/admin/boards/pictures/${id}`)
      .then(() => {
        Alert.info('삭제되었습니다!');
        getArticles();
      })
      .catch(err => {
        Alert.error(err.response.data.message);
      });
    }
  };

  const exportToExcel = () => {
    axios(`/admin/boards/pictures/export`, {
      responseType: 'blob'
    })
    .then(res => {
      fileDownload(res.data, '[바네스데코] 사진 게시글 리스트.xlsx');
    });
  };

  const toggleArticle = (id) => {
    axios.put(`/admin/boards/pictures/status/${id}`)
    .then(() => {
      getArticles();
    })
    .catch(err => {
      Alert.error("오류가 발생했습니다!");
    });
  };

  useEffect(() => {
    getArticles();
  }, [debouncedQuery]);

  return (
    <Page pageName="picture">
      <h3>사진 게시글 리스트</h3>
      <Input type="text" placeholder="검색어를 입력해주세요" style={{ width: '20%', marginTop: 8, marginBottom: 8 }} value={searchQuery} onChange={setSearchQuery} />
      <Button onClick={exportToExcel} appearance="primary">엑셀 다운로드</Button>
      <Table autoHeight data={articles} loading={loading} rowHeight={64}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>평수</Table.HeaderCell>
          <Table.Cell dataKey="houseSize" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>거주형태</Table.HeaderCell>
          <Table.Cell dataKey="housingType" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>작성자</Table.HeaderCell>
          <Table.Cell dataKey="userId" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>등록일자</Table.HeaderCell>
          <Table.Cell>
            {
              (rowData) => dayjs(rowData.createdAt).format("YYYY년 MM월 DD일")
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1.5}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell>
          {
              (rowData) => (
                <>
                  <Button
                    appearance="primary"
                    onClick={() => {
                      window.open(`http://vanessdeco.com/community/photo/detail.html?id=${rowData.id}`)
                    }}
                  >
                    게시글 보기
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    appearance="primary"
                    color={rowData.isActive ? 'red' : 'green'}
                    onClick={() => toggleArticle(rowData.id)}
                  >
                    { rowData.isActive ? '비활성화' : '활성화' }
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    appearance="default"
                    color="red"
                    onClick={() => removeArticle(rowData.id)}
                  >
                    삭제
                  </Button>
                </>
              )
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </Page>
  );
};

export default Picture;