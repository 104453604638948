import React, { useEffect, useState } from 'react';
import {
  Table,
  Alert,
  Button,
  Modal,
  Grid,
  Row,
  Col,
  Input,
  Divider
} from 'rsuite';
import axios from 'axios';

import Page from '../../components/Page';

const User = () => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [accountPassword, setAccountPassword] = useState('');

  const addAccount = () => {
    if (!accountId) {
      alert('아이디가 입력되지 않았습니다!');
    }

    if (!accountPassword) {
      alert('비밀번호가 입력되지 않았습니다!');
    }

    axios({
      method: "POST",
      url: '/admin/accounts',
      data: {
        account: accountId,
        password: accountPassword
      }
    }).then(() => {
      alert('아이디가 생성되었습니다!');
      axios.get('/admin/accounts')
      .then(res => res.data)
      .then(res => {
        setAccounts(res.admins);
        setLoading(false);
        setShowAddAccount(false);
      })
      .catch(err => {
        Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
        setLoading(false);
      });
    }).catch(err => {
      alert(err.response.data.message);
    });
  };

  const removeAccount = (id) => {
    if (window.confirm('계정을 삭제하시겠습니까?')) {
      axios({
        method: "DELETE",
        url: `/admin/accounts/${id}`
      }).then(() => {
        alert('계정이 삭제되었습니다!');
        axios.get('/admin/accounts')
        .then(res => res.data)
        .then(res => {
          setAccounts(res.admins);
          setLoading(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        });
      }).catch(err => {
        alert(err.response.data.message);
      });
    }
  };

  useEffect(() => {
    axios.get('/admin/accounts')
    .then(res => res.data)
    .then(res => {
      setAccounts(res.admins);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    setAccountId('');
    setAccountPassword('');
  }, [showAddAccount]);

  return (
    <Page pageName="admin">
      <h3>관리자 계정 리스트</h3>
      <Modal show={showAddAccount} onHide={() => setShowAddAccount(false)}>
        <Modal.Header>관리자 계정 추가</Modal.Header>
        <Modal.Body>
          <Grid style={{ width: '100%' }}>
            <Row>
              <Col xs={6}>아이디</Col>
              <Col xs={12}>
                <Input type="text" value={accountId} onChange={setAccountId} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>비밀번호</Col>
              <Col xs={12}>
                <Input type="password" value={accountPassword} onChange={setAccountPassword} />
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={addAccount} appearance="primary">등록</Button>
        </Modal.Footer>
      </Modal>
      <Button appearance="primary" onClick={setShowAddAccount}>추가</Button>
      <Table autoHeight data={accounts} loading={loading} rowHeight={64}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>아이디</Table.HeaderCell>
          <Table.Cell dataKey="account" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell>
          {
              (rowData) => (
                <>
                  <Button
                    onClick={() => {
                      removeAccount(rowData.id)
                    }}
                    style={{ marginLeft: 8 }}
                    appearance="default"
                    color="red"
                  >
                    삭제
                  </Button>
                </>
              )
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </Page>
  );
};

export default User;
        