import React from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { RecoilRoot, useRecoilValue } from 'recoil';
import axios from 'axios';

import tokenState from './atoms/tokenState';
import AuthRoute from './components/AuthRoute';

import Login from './pages/Login';
import User from './pages/User';
import AdminAccount from './pages/AdminAccount';
import Report from './pages/Report';
import LivingTv from './pages/board/livingTv';
import HouseWarming from './pages/board/houseWarming';
import Life from './pages/board/life';
import Knowhow from './pages/board/knowhow';
import Picture from './pages/board/picture';
import Event from './pages/board/event';
import StoreEvent from './pages/board/event/store';
import Search from './pages/Search';

import HouseWarmingSort from './pages/board/houseWarming/sort';
import LifeSort from './pages/board/life/sort';
import KnowhowSort from './pages/board/knowhow/sort';
import PictureSort from './pages/board/picture/sort';
import HouseWarmingHomeSort from './pages/board/houseWarming/homeSort';
import LifeHomeSort from './pages/board/life/homeSort';
import KnowhowHomeSort from './pages/board/knowhow/homeSort';
import PictureHomeSort from './pages/board/picture/homeSort';

import Banner from './pages/Banner';
import BannerMobile from './pages/Banner/mobile';

import 'rsuite/dist/styles/rsuite-default.css';

const redirectToLogin = () => <Redirect to="/login" />;

const RouteApp = () => {
  const token = useRecoilValue(tokenState);

  axios.defaults.baseURL = "https://api.vanessdeco.com/";
  axios.defaults.headers.common['Authorization'] = token;
  
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/user" />} />
        <Route path="/login" exact component={() => <Login token={token} />} />
        <AuthRoute path="/user" exact component={User} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/admin" exact component={AdminAccount} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/report" exact component={Report} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/livingTv" exact component={LivingTv} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/houseWarming" exact component={HouseWarming} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/houseWarming/sort" exact component={HouseWarmingSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/houseWarming/homeSort" exact component={HouseWarmingHomeSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/life" exact component={Life} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/life/sort" exact component={LifeSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/life/homeSort" exact component={LifeHomeSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/knowhow" exact component={Knowhow} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/knowhow/sort" exact component={KnowhowSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/knowhow/homeSort" exact component={KnowhowHomeSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/picture" exact component={Picture} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/picture/sort" exact component={PictureSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/picture/homeSort" exact component={PictureHomeSort} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/event" exact component={Event} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/board/event/store" exact component={StoreEvent} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/search" exact component={Search} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/banner" exact component={Banner} token={token} fallback={redirectToLogin} />
        <AuthRoute path="/banner/mobile" exact component={BannerMobile} token={token} fallback={redirectToLogin} />
      </Switch>
    </BrowserRouter>
  );
};

const App = () => (
  <RecoilRoot>
    <RouteApp />
  </RecoilRoot>
);

export default App;