import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Alert,
  Button,
  Modal,
  Grid,
  Row,
  Col,
  Input,
  Divider,
  Icon
} from 'rsuite';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';

import Page from '../../components/Page';
import dayjs from 'dayjs';

const DraggableCell = ({ children, onDrag, id, rowData, ...rest }) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'row',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop(item, monitor) {
      onDrag && onDrag(item.id, rowData.id);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { id: rowData.id, type: 'row' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  return (
    <Table.Cell {...rest} style={{ padding: '1rem', cursor: 'move' }}>
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, background: isActive ? "#ddd" : null }}>
        {children}
      </div>
    </Table.Cell>
  )
};

const Banner = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [title, setTitle] = useState('')
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    console.log(articles, 'articles');
  }, [articles]);

  const getArticles = () => {
    setLoading(true);

    axios.get(`/admin/banners?type=mobile`)
    .then(res => res.data)
    .then(res => {
      setArticles(res.banners);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    });
  };

  const sort = async (source, sourceId, targetId) => {
    const nextData = source.filter((item) => item.id !== sourceId);
    const dragItem = source.find((item) => item.id === sourceId);
    const index = nextData.findIndex((item) => item.id === targetId);
  
    nextData.splice(index + 1, 0 ,dragItem);
    await changeSort(sourceId, targetId);
  
    return nextData;
  };
  
  const changeSort = async (source, target) => {
    setLoading(true);

    await axios({
      method: 'PUT',
      url: '/admin/banners/sort',
      data: {
        target,
        source
      }
    });

    getArticles();
  }

  const handleDragRow = (sourceId, targetId) => {
    sort(articles, sourceId, targetId);
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    setCategory('');
    setTitle('');
    setImage(null);
    setSubTitle('');
    setLink('');
  }, [showAddModal]);

  const add = () => {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('image', image);
    formData.append('category', category);
    formData.append('subTitle', subTitle);
    formData.append('link', link);
    formData.append('type', 'mobile');

    axios({
      method: "POST",
      url: '/admin/banners',
      data: formData
    }).then(() => {
      Alert.info("등록되었습니다!");

      setShowAddModal(false);
      setLoading(true);
      getArticles();
    }).catch(err => {
      Alert.error(err.response.data.message);
      setLoading(false);
    })
  };

  const remove = (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      axios.delete(`/admin/banners/${id}`)
      .then(() => {
        Alert.info('이벤트가 삭제되었습니다!');
        getArticles();
      })
      .catch(err => {
        Alert.error(err.response.data.message);
      })
    }
  };

  const toggleArticle = (id) => {
    axios.put(`/admin/banners/status/${id}`)
    .then(() => {
      getArticles();
    })
    .catch(err => {
      Alert.error("오류가 발생했습니다!");
    });
  };

  return (
    <Page pageName="bannerMobile">
      <h3>배너 리스트 (모바일)</h3>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header>배너 등록</Modal.Header>
        <Modal.Body>
          <Grid style={{ width: '100%' }}>
            <Row>
              <Col xs={6}>제목</Col>
              <Col xs={12}>
                <Input type="text" value={title} onChange={setTitle} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>배너</Col>
              <Col xs={12}>
                <input type="file" onChange={(event) => event.target.files.length ? setImage(event.target.files[0]) : null} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>카테고리</Col>
              <Col xs={12}>
                <Input type="text" value={category} onChange={setCategory} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>서브 타이틀</Col>
              <Col xs={12}>
                <Input type="text" value={subTitle} onChange={setSubTitle} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>링크</Col>
              <Col xs={12}>
                <Input type="text" value={link} onChange={setLink} />
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={add} appearance="primary">등록</Button>
        </Modal.Footer>
      </Modal>
      <Button appearance="primary" onClick={setShowAddModal}>추가</Button>
      <DndProvider backend={HTML5Backend}>
        <Table autoHeight data={articles} loading={loading} rowHeight={72}>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.Cell dataKey="title" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>이미지</Table.HeaderCell>
            <Table.Cell>
            {
                (rowData) => <img src={rowData.image} width="auto" height="64" />
            }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>카테고리</Table.HeaderCell>
            <Table.Cell dataKey="category" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>서브 타이틀</Table.HeaderCell>
            <Table.Cell dataKey="subTitle" />
          </Table.Column>
          <Table.Column flexGrow={1}>
              <Table.HeaderCell>링크</Table.HeaderCell>
              <Table.Cell>
              {
                (rowData) => <a href={rowData.link} target="blank">{rowData.link}</a>
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>등록일자</Table.HeaderCell>
            <Table.Cell>
              {
                (rowData) => dayjs(rowData.createdAt).format("YYYY년 MM월 DD일")
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.Cell>
            {
                (rowData) => (
                  <>
                    <Button
                      appearance="default"
                      color="red"
                      onClick={() => remove(rowData.id)}
                    >
                      삭제
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      appearance="default"
                      color={rowData.isActive ? 'red' : 'green'}
                      onClick={() => toggleArticle(rowData.id)}
                    >
                      {rowData.isActive ? '비활성화' : '활성화'}
                    </Button>
                  </>
                )
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>정렬</Table.HeaderCell>
            <DraggableCell onDrag={handleDragRow}>
              <Icon icon="arrows"/>
            </DraggableCell>
          </Table.Column>
        </Table>
      </DndProvider>
    </Page>
  );
};

export default Banner;