import React, { useState, useEffect } from 'react';
import {
    Container,
    Content,
    FlexboxGrid,
    Panel,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    ButtonToolbar,
    Button,
    Alert,
    Loader,
} from 'rsuite';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import tokenState from '../../atoms/tokenState';

const Login = ({ token: propToken }) => {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const setToken = useSetRecoilState(tokenState);
    const router = useHistory();

    const loginAction = () => {
        setLoading(true);

        axios.post('/admin/login', {
            account: userId,
            password: password
        }).then(res => res.data)
        .then(res => {
            setToken(res.token);
            window.localStorage.setItem('vanessdeco-admin-token', res.token);
            router.push('/user');
            setLoading(false);
        }).catch(err => {
            Alert.error('로그인에 실패했습니다!');
            setLoading(false);
        });
    };

    useEffect(() => {
        console.log(propToken);
        if (propToken) {
            router.push("/user");
        }
    }, []);

    return (
        <Container style={{ background: '#f7f7fa', height: '100%' }}>
            <Content>
                <FlexboxGrid justify="center" style={{ marginTop: '10%' }}>
                    <FlexboxGrid.Item colspan={12}>
                        <Panel header={<h3>로그인</h3>} bordered style={{ background: '#ffffff' }}>
                            <Form
                                fluid
                                onSubmit={loginAction}
                            >
                                <FormGroup>
                                    <ControlLabel>아이디</ControlLabel>
                                    <FormControl value={userId} onChange={setUserId} name="name" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>비밀번호</ControlLabel>
                                    <FormControl value={password} onChange={setPassword} name="password" type="password" />
                                </FormGroup>
                                <FormGroup>
                                <ButtonToolbar>
                                    <Button type="submit" appearance="primary" disabled={!(userId && password)}>
                                    {
                                        loading ? <Loader /> : '로그인'
                                    }
                                    </Button>
                                </ButtonToolbar>
                                </FormGroup>
                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default Login;