import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Alert,
  Button,
  Input,
  Icon
} from 'rsuite';
import axios from 'axios';
import fileDownload from 'js-file-download';
import useDebounce from '../../../hooks/useDebounce';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Page from '../../../components/Page';
import dayjs from 'dayjs';

const DraggableCell = ({ children, onDrag, id, rowData, ...rest }) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'row',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop(item, monitor) {
      onDrag && onDrag(item.id, rowData.id);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { id: rowData.id, type: 'row' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  return (
    <Table.Cell {...rest} style={{ padding: '1rem', cursor: 'move' }}>
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, background: isActive ? "#ddd" : null }}>
        {children}
      </div>
    </Table.Cell>
  )
};

const Life = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);

  const getArticles = () => {
    axios.get(`/admin/boards/lifes?query=${debouncedQuery}&sort=popularity`)
    .then(res => res.data)
    .then(res => {
      setArticles(res.lifes);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    });
  };

  const removeArticle = (id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios.delete(`/admin/boards/lifes/${id}`)
      .then(() => {
        Alert.info('삭제되었습니다!');
        axios.get('/admin/boards/lifes')
        .then(res => res.data)
        .then(res => {
          setArticles(res.lifes);
          setLoading(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        });
      })
      .catch(err => {
        Alert.error(err.response.data.message);
      });
    }
  };

  const exportToExcel = () => {
    axios(`/admin/boards/lifes/export`, {
      responseType: 'blob'
    })
    .then(res => {
      fileDownload(res.data, '[바네스데코] 라이프 리스트.xlsx');
    });
  };

  const toggleArticle = (id) => {
    axios.put(`/admin/boards/lifes/status/${id}`)
    .then(() => {
      getArticles();
    })
    .catch(err => {
      Alert.error("오류가 발생했습니다!");
    });
  };

  const toggleRecent = (id) => {
    axios.put(`/admin/boards/lifes/recent/${id}`)
    .then(() => {
      getArticles();
    })
    .catch(err => {
      Alert.error("오류가 발생했습니다!");
    });
  };

  const sort = async (source, sourceId, targetId) => {
    const nextData = source.filter((item) => item.id !== sourceId);
    const dragItem = source.find((item) => item.id === sourceId);
    const index = nextData.findIndex((item) => item.id === targetId);
  
    nextData.splice(index + 1, 0 ,dragItem);
    await changeSort(sourceId, targetId);
  
    return nextData;
  };
  
  const changeSort = async (source, target) => {
    setLoading(true);

    await axios({
      method: 'PUT',
      url: '/admin/boards/lifes/sort',
      data: {
        target,
        source
      }
    });

    getArticles();
  }

  const handleDragRow = (sourceId, targetId) => {
    sort(articles, sourceId, targetId);
  };

  useEffect(() => {
    getArticles();
  }, [debouncedQuery]);

  return (
    <Page pageName="lifeSort">
      <h3>라이프 게시글 인기순 정렬 관리</h3>
      <Input type="text" placeholder="검색어를 입력해주세요" style={{ width: '20%', marginTop: 8, marginBottom: 8 }} value={searchQuery} onChange={setSearchQuery} />
      <Button onClick={exportToExcel} appearance="primary">엑셀 다운로드</Button>
      <DndProvider backend={HTML5Backend}>
        <Table autoHeight data={articles} loading={loading} rowHeight={64}>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.Cell dataKey="id" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>카테고리</Table.HeaderCell>
            <Table.Cell dataKey="category" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>작성자</Table.HeaderCell>
            <Table.Cell dataKey="userId" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>등록일자</Table.HeaderCell>
            <Table.Cell>
              {
                (rowData) => dayjs(rowData.createdAt).format("YYYY년 MM월 DD일")
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1.5}>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.Cell>
            {
                (rowData) => (
                  <>
                    <Button
                      appearance="primary"
                      onClick={() => {
                        window.open(`http://vanessdeco.com/community/life/detail.html?id=${rowData.id}`)
                      }}
                    >
                      게시글 보기
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      appearance="primary"
                      color={rowData.isActive ? 'red' : 'green'}
                      onClick={() => toggleArticle(rowData.id)}
                    >
                      { rowData.isActive ? '비활성화' : '활성화' }
                    </Button>
                    <Button
                        style={{ marginTop: 8 }}
                        appearance="primary"
                        color={rowData.isRecent ? 'red' : 'green'}
                        onClick={() => toggleRecent(rowData.id)}
                    >
                        { rowData.isRecent ? '인기글 노출 해제' : '인기글 노출' }
                    </Button>
                  </>
                )
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>정렬</Table.HeaderCell>
            <DraggableCell onDrag={handleDragRow}>
              <Icon icon="arrows"/>
            </DraggableCell>
          </Table.Column>
        </Table>
      </DndProvider>
    </Page>
  );
};

export default Life;