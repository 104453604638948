import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import {
  Container, Sidebar, Sidenav, Content, Nav, Dropdown, Icon, Navbar, Alert
} from 'rsuite';

import tokenState from '../atoms/tokenState';

const headerStyles = {
  padding: 18,
  fontSize: 16,
  height: 56,
  background: '#34c3ff',
  color: ' #fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const Page = ({ children, pageName }) => {
  const history = useHistory();
  const setToken = useSetRecoilState(tokenState);

  return (
    <Container style={{ width: '100vw', height: '100vh', background: '#f7f7fa' }}>
      <Sidebar style={{ display: 'flex', flexDirection: 'column', background: '#ffffff' }}>
        <Sidenav.Header>
          <div style={headerStyles}>
            <span>바네스데코 커뮤니티 관리자</span>
          </div>
        </Sidenav.Header>
        <Sidenav
          appearance="subtle"
          style={{ flex: '1 1 auto' }}
        >
          <Sidenav.Body>
            <Nav>
              <Nav.Item onClick={() => history.push('/user')} eventKey="1" active={pageName === 'user'} icon={<Icon icon="user" />}>
                사용자 목록
              </Nav.Item>
              <Nav.Item onClick={() => history.push('/admin')} eventKey="2" active={pageName === 'admin'} icon={<Icon icon="lock" />}>
                관리자 계정 목록
              </Nav.Item>
              <Nav.Item onClick={() => history.push('/report')} eventKey="3" active={pageName === 'report'} icon={<Icon icon="flag" />}>
                신고 목록
              </Nav.Item>
              <Dropdown eventKey="4" title="게시판 관리" icon={<Icon icon="comment" />}>
                <Dropdown.Item eventKey="4-1" onClick={() => history.push('/board/livingTv')} active={pageName === 'livingTv'} icon={<Icon icon="youtube-play" />}>리빙TV 관리</Dropdown.Item>
                <Dropdown.Menu eventKey="4-3" title="사진 관리" icon={<Icon icon="camera" />}>
                  <Dropdown.Item eventKey="4-3-1" onClick={() => history.push('/board/picture')} active={pageName === 'picture'}>게시글 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-3-2" onClick={() => history.push('/board/picture/sort')} active={pageName === 'pictureSort'}>인기순 정렬 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-3-3" onClick={() => history.push('/board/picture/homeSort')} active={pageName === 'pictureHomeSort'}>메인 정렬 관리</Dropdown.Item>
                </Dropdown.Menu>
                <Dropdown.Menu eventKey="4-2" title="집들이 관리" icon={<Icon icon="home" />}>
                  <Dropdown.Item eventKey="4-2-1" onClick={() => history.push('/board/houseWarming')} active={pageName === 'houseWarming'}>게시글 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-2-2" onClick={() => history.push('/board/houseWarming/sort')} active={pageName === 'houseWarmingSort'}>인기순 정렬 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-2-3" onClick={() => history.push('/board/houseWarming/sort')} active={pageName === 'houseWarmingHomeSort'}>메인 정렬 관리</Dropdown.Item>
                </Dropdown.Menu>
                <Dropdown.Menu eventKey="4-4" title="노하우 관리" icon={<Icon icon="question" />}>
                  <Dropdown.Item eventKey="4-4-1" onClick={() => history.push('/board/knowhow')} active={pageName === 'knowhow'}>게시글 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-4-2" onClick={() => history.push('/board/knowhow/sort')} active={pageName === 'knowhowSort'}>인기순 정렬 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-4-3" onClick={() => history.push('/board/knowhow/homeSort')} active={pageName === 'knowhowHomeSort'}>메인 정렬 관리</Dropdown.Item>
                </Dropdown.Menu>
                <Dropdown.Menu eventKey="4-5" title="라이프 관리" icon={<Icon icon="camera" />}>
                  <Dropdown.Item eventKey="4-5-1" onClick={() => history.push('/board/life')} active={pageName === 'life'}>게시글 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-5-2" onClick={() => history.push('/board/life/sort')} active={pageName === 'lifeSort'}>인기순 정렬 관리</Dropdown.Item>
                  <Dropdown.Item eventKey="4-5-3" onClick={() => history.push('/board/life/homeSort')} active={pageName === 'lifeHomeSort'}>메인 정렬 관리</Dropdown.Item>
                </Dropdown.Menu>
                <Dropdown.Item eventKey="4-6" onClick={() => history.push('/board/event')} active={pageName === 'event'} icon={<Icon icon="tags" />}>이벤트 관리</Dropdown.Item>
                <Dropdown.Item eventKey="4-7" onClick={() => history.push('/board/event/store')} active={pageName === 'storeEvent'} icon={<Icon icon="tags" />}>기획전 관리</Dropdown.Item>
              </Dropdown>
              <Nav.Item onClick={() => history.push('/search')} eventKey="5" active={pageName === 'search'} icon={<Icon icon="search" />}>
                인기 검색어 관리
              </Nav.Item>
              <Nav.Item onClick={() => history.push('/banner')} eventKey="5" active={pageName === 'banner'} icon={<Icon icon="home" />}>
                메인 배너 관리 (PC)
              </Nav.Item>
              <Nav.Item onClick={() => history.push('/banner/mobile')} eventKey="5" active={pageName === 'bannerMobile'} icon={<Icon icon="home" />}>
                메인 배너 관리 (모바일)
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
        <Navbar appearance="subtle" style={{ borderTop: '1px solid #e5e5ea' }}>
          <Navbar.Body>
            <Nav>
              <Nav.Item onClick={() => {
                  Alert.success('로그아웃 되었습니다');
                  setToken(null);
                  localStorage.removeItem('vanessdeco-admin-token');
                }}>
                <Icon icon="sign-out" />
              </Nav.Item>
            </Nav>
          </Navbar.Body>
        </Navbar>
      </Sidebar>
      <Container>
        <Content style={{ margin: 20, padding: 20, background: '#ffffff', overflowY: 'scroll' }}>{ children }</Content>
      </Container>
    </Container>
  );
};

export default Page;
