import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Alert,
  Button,
  Modal,
  Grid,
  Row,
  Col,
  Input,
  Divider,
  Icon
} from 'rsuite';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';

import Page from '../../../components/Page';
import dayjs from 'dayjs';
import useDebounce from '../../../hooks/useDebounce';

const DraggableCell = ({ children, onDrag, id, rowData, ...rest }) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'row',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop(item, monitor) {
      onDrag && onDrag(item.id, rowData.id);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { id: rowData.id, type: 'row' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  return (
    <Table.Cell {...rest} style={{ padding: '1rem', cursor: 'move' }}>
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, background: isActive ? "#ddd" : null }}>
        {children}
      </div>
    </Table.Cell>
  )
};

const Event = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [thumb, setThumb] = useState(null);
  const [pcLink, setPcLink] = useState("");
  const [mobileLink, setMobileLink] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [productCount, setProductCount] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);

  const sort = async (source, sourceId, targetId) => {
    const nextData = source.filter((item) => item.id !== sourceId);
    const dragItem = source.find((item) => item.id === sourceId);
    const index = nextData.findIndex((item) => item.id === targetId);
  
    nextData.splice(index + 1, 0 ,dragItem);
    await changeSort(source, sourceId, targetId);
  
    return nextData;
  };
  
  const changeSort = async (dataSource, sourceId, targetId) => {
    const source = dataSource.find(item => item.id === sourceId);
    const target = dataSource.find(item => item.id === targetId);

    setLoading(true);

    await Promise.all([
      axios({
        method: 'PUT',
        url: '/admin/boards/events',
        data: {
          ...target,
          sort: source.sort
        }
      }),
      axios({
        method: 'PUT',
        url: '/admin/boards/events',
        data: {
          ...source,
          sort: target.sort
        }
      })
    ]);

    axios.get(`/admin/boards/events?category=store&query=${debouncedQuery}`)
    .then(res => res.data)
    .then(res => {
      setArticles(res.events);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    });
  }

  const handleDragRow = (sourceId, targetId) => {
    sort(articles, sourceId, targetId);
  };
  useEffect(() => {
    axios.get(`/admin/boards/events?category=store&query=${debouncedQuery}`)
    .then(res => res.data)
    .then(res => {
      setArticles(res.events);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    })
  }, [debouncedQuery]);

  useEffect(() => {
    setCategory('store');
    setTitle('');
    setContents('');
    setStartAt('');
    setEndAt('');
    setPcLink('');
    setMobileLink('');
    setSubTitle('');
    setProductCount('');
    setThumb(null);
  }, [showAddModal]);

  const add = () => {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('contents', contents);
    formData.append('startAt', startAt);
    formData.append('endAt', endAt);
    formData.append('thumb', thumb);
    formData.append('pcLink', pcLink);
    formData.append('mobileLink', mobileLink);
    formData.append('category', 'store');
    formData.append('subTitle', subTitle);
    formData.append('productCount', productCount);

    axios({
      method: "POST",
      url: '/admin/boards/events',
      data: formData
    }).then(() => {
      alert('기획전이 등록되었습니다!');
      axios.get(`/admin/boards/events?category=store&query=${debouncedQuery}`)
      .then(res => res.data)
      .then(res => {
        setArticles(res.events);
        setLoading(false);
        setShowAddModal(false);
      })
      .catch(err => {
        Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
        setLoading(false);
      })
    }).catch(err => {
      Alert.error(err.response.data.message);
      setLoading(false);
    })
  };

  const remove = (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      axios.delete(`/admin/boards/events/${id}`)
      .then(() => {
        Alert.info('기획전이 삭제되었습니다!');
  
        axios.get(`/admin/boards/events?category=store&query=${debouncedQuery}`)
        .then(res => res.data)
        .then(res => {
          setArticles(res.events);
          setLoading(false);
          setShowAddModal(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        });
      })
      .catch(err => {
        Alert.error(err.response.data.message);
      })
    }
  };

  const enableToggle = (data) => {
    if (window.confirm(data.isActive ? '비활성화 하시겠습니까?' : '활성화 하시겠습니까?')) {
      axios({
        method: 'PUT',
        url: '/admin/boards/events',
        data: {
          ...data,
          isActive: !data.isActive
        }
      })
      .then(() => {
        Alert.info(data.isActive ? '기획전이 비활성화 되었습니다!' : '기획전이 활성화 되었습니다!');
  
        axios.get(`/admin/boards/events?category=store&query=${debouncedQuery}`)
        .then(res => res.data)
        .then(res => {
          setArticles(res.events);
          setLoading(false);
          setShowAddModal(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        });
      })
      .catch(err => {
        Alert.error(err.response.data.message);
      })
    }
  };

  return (
    <Page pageName="storeEvent">
      <h3>기획전 리스트</h3>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header>기획전 게시글 등록</Modal.Header>
        <Modal.Body>
          <Grid style={{ width: '100%' }}>
            <Row>
              <Col xs={6}>제목</Col>
              <Col xs={12}>
                <Input type="text" value={title} onChange={setTitle} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>기획전 내용</Col>
              <Col xs={12}>
                <input type="file" onChange={(event) => event.target.files.length ? setContents(event.target.files[0]) : null} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>시작일자</Col>
              <Col xs={12}>
                <Input type="date" value={startAt} onChange={setStartAt} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>종료일자</Col>
              <Col xs={12}>
                <Input type="date" value={endAt} onChange={setEndAt} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>썸네일</Col>
              <Col xs={12}>
                <input type="file" onChange={(event) => event.target.files.length ? setThumb(event.target.files[0]) : null} />
              </Col>
            </Row>
            <Divider />
            <Row>
                <Col xs={6}>PC 링크</Col>
                <Col xs={12}>
                    <Input type="text" onChange={setPcLink} value={pcLink} />
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col xs={6}>모바일 링크</Col>
                <Col xs={12}>
                    <Input type="text" onChange={setMobileLink} value={mobileLink} />
                </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>서브타이틀</Col>
              <Col xs={12}>
                <Input type="text" onChange={setSubTitle} value={subTitle} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>상품 갯수</Col>
              <Col xs={12}>
                <Input type="text" onChange={setProductCount} value={productCount} />
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={add} appearance="primary">등록</Button>
        </Modal.Footer>
      </Modal>
      <Button appearance="primary" onClick={setShowAddModal}>추가</Button>
      <Input type="text" placeholder="검색어를 입력해주세요" style={{ width: '20%', marginTop: 8, marginBottom: 8 }} value={searchQuery} onChange={setSearchQuery} />
      <DndProvider backend={HTML5Backend}>
        <Table autoHeight data={articles} loading={loading} rowHeight={64}>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.Cell dataKey="title" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>시작일자</Table.HeaderCell>
            <Table.Cell dataKey="startAt" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>종료일자</Table.HeaderCell>
            <Table.Cell dataKey="endAt" />
          </Table.Column>
          <Table.Column flexGrow={1}>
              <Table.HeaderCell>PC URL</Table.HeaderCell>
              <Table.Cell>
                  {
                      (rowData) => <a href={rowData.pcLink} target='blank'>{rowData.pcLink}</a>
                  }
              </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
              <Table.HeaderCell>모바일 URL</Table.HeaderCell>
              <Table.Cell>
                  {
                      (rowData) => <a href={rowData.mobileLink} target='blank'>{rowData.mobileLink}</a>
                  }
              </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>등록자</Table.HeaderCell>
            <Table.Cell dataKey="adminId" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>등록일자</Table.HeaderCell>
            <Table.Cell>
              {
                (rowData) => dayjs(rowData.createdAt).format("YYYY년 MM월 DD일")
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.Cell>
            {
                (rowData) => (
                  <>
                    <Button
                      style={{ marginLeft: 8 }}
                      appearance="default"
                      color="red"
                      onClick={() => remove(rowData.id)}
                    >
                      삭제
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      appearance="default"
                      color={rowData.isActive ? 'red' : 'green'}
                      onClick={() => enableToggle(rowData)}
                    >
                      {rowData.isActive ? '비활성화' : '활성화'}
                    </Button>
                  </>
                )
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>정렬</Table.HeaderCell>
            <DraggableCell onDrag={handleDragRow}>
              <Icon icon="arrows"/>
            </DraggableCell>
          </Table.Column>
        </Table>
      </DndProvider>
    </Page>
  );
};

export default Event;