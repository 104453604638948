import React, { useEffect, useState } from 'react';
import {
  Table,
  Alert,
  Button,
  Modal,
  Input,
  Grid,
  Row,
  Col
} from 'rsuite';
import axios from 'axios';

import Page from '../../components/Page';

const SearchKeywords = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    axios.get('/admin/search')
    .then(res => res.data)
    .then(res => {
      setArticles(res.searches);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    setQuery('');
  }, [showAddModal]);

  const removeArticle = (idx) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios.delete(`/admin/search/${idx}`)
      .then(() => {
        alert('삭제되었습니다!');

        axios.get('/admin/search')
        .then(res => res.data)
        .then(res => {
          setArticles(res.searches);
          setLoading(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        })
      }).catch((err) => {
        alert(err.response.data.message);
      });
    }
  };

  const add = () => {
    axios({
      method: 'POST',
      url: '/admin/search',
      data: {
        query
      }
    }).then(() => {
      axios.get('/admin/search')
      .then(res => res.data)
      .then(res => {
        alert('인기 검색어가 등록되었습니다!');
        setShowAddModal(false);
        setArticles(res.searches);
        setLoading(false);
      })
      .catch(err => {
        Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
        setLoading(false);
      });
    }).catch((err) => {
      alert(err.response.data.message);
    });
  };

  return (
    <Page pageName="search">
      <h3>인기 검색어 리스트</h3>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header>인기 검색어 등록</Modal.Header>
        <Modal.Body>
          <Grid style={{ width: '100%' }}>
            <Row>
              <Col xs={6}>검색어</Col>
              <Col xs={12}>
                <Input type="text" value={query} onChange={setQuery} />
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={add} appearance="primary">등록</Button>
        </Modal.Footer>
      </Modal>
      <Button appearance="primary" onClick={setShowAddModal}>추가</Button>
      <Table autoHeight data={articles} loading={loading} rowHeight={64}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>검색어</Table.HeaderCell>
          <Table.Cell dataKey="query" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.Cell>
          {
              (rowData) => (
                <>
                  <Button
                    style={{ marginLeft: 8 }}
                    appearance="default"
                    color="red"
                    onClick={() => removeArticle(rowData.id)}
                  >
                    삭제
                  </Button>
                </>
              )
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </Page>
  );
};

export default SearchKeywords;