import React, { useEffect, useState, useRef } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Table,
  Alert,
  Button,
  Modal,
  Input,
  Grid,
  Row,
  Col,
  Divider,
  Icon
} from 'rsuite';
import axios from 'axios';

import Page from '../../../components/Page';
import dayjs from 'dayjs';

const DraggableCell = ({ children, onDrag, id, rowData, ...rest }) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'row',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop(item, monitor) {
      onDrag && onDrag(item.id, rowData.id);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { id: rowData.id, type: 'row' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  return (
    <Table.Cell {...rest} style={{ padding: '1rem', cursor: 'move' }}>
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, background: isActive ? "#ddd" : null }}>
        {children}
      </div>
    </Table.Cell>
  )
};

const LivingTv = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const sort = async (source, sourceId, targetId) => {
    const nextData = source.filter((item) => item.id !== sourceId);
    const dragItem = source.find((item) => item.id === sourceId);
    const index = nextData.findIndex((item) => item.id === targetId);
  
    nextData.splice(index + 1, 0 ,dragItem);
    await changeSort(source, sourceId, targetId);
  
    return nextData;
  };
  
  const changeSort = async (dataSource, sourceId, targetId) => {
    const source = dataSource.find(item => item.id === sourceId);
    const target = dataSource.find(item => item.id === targetId);

    setLoading(true);

    await Promise.all([
      axios({
        method: 'PUT',
        url: '/admin/boards/living-tv',
        data: {
          ...target,
          sort: source.sort
        }
      }),
      axios({
        method: 'PUT',
        url: '/admin/boards/living-tv',
        data: {
          ...source,
          sort: target.sort
        }
      })
    ]);

    setLoading(false);

    setArticles(
      dataSource.map(item => ({
        ...item,
        sort: item.id === sourceId ? target.sort : item.id === targetId ? source.sort : item.sort
      })).sort((a, b) => b.sort - a.sort)
    );
  }

  useEffect(() => {
    axios.get('/admin/boards/living-tv')
    .then(res => res.data)
    .then(res => {
      setArticles(res.livingTvs);
      setLoading(false);
    })
    .catch(err => {
      Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    setYoutubeUrl('');
    setName('');
    setDescription('');
  }, [showAddModal]);

  const removeArticle = (idx) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios.delete(`/admin/boards/living-tv/${idx}`)
      .then(() => {
        alert('삭제되었습니다!');
        axios.get('/admin/boards/living-tv')
        .then(res => res.data)
        .then(res => {
          setArticles(res.livingTvs);
          setLoading(false);
        })
        .catch(err => {
          Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
          setLoading(false);
        })
      }).catch((err) => {
        alert(err.response.data.message);
      });
    }
  };

  const add = () => {
    axios({
      method: 'POST',
      url: '/admin/boards/living-tv',
      data: {
        youtubeUrl,
        name,
        description
      }
    }).then(() => {
      axios.get('/admin/boards/living-tv')
      .then(res => res.data)
      .then(res => {
        alert('게시글이 등록되었습니다!');
        setShowAddModal(false);
        setArticles(res.livingTvs);
        setLoading(false);
      })
      .catch(err => {
        Alert.error("데이터를 가져오던 중 오류가 발생했습니다!");
        setLoading(false);
      });
    }).catch((err) => {
      alert(err.response.data.message);
    });
  };

  const handleDragRow = (sourceId, targetId) => {
    sort(articles, sourceId, targetId);
  };

  return (
    <Page pageName="livingTv">
      <h3>리빙TV 게시글 리스트</h3>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header>리빙TV 게시글 등록</Modal.Header>
        <Modal.Body>
          <Grid style={{ width: '100%' }}>
            <Row>
              <Col xs={6}>제목</Col>
              <Col xs={12}>
                <Input type="text" value={name} onChange={setName} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>설명</Col>
              <Col xs={12}>
                <Input type="text" value={description} onChange={setDescription} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={6}>유튜브 URL</Col>
              <Col xs={12}>
                <Input type="text" value={youtubeUrl} onChange={setYoutubeUrl} />
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={add} appearance="primary">등록</Button>
        </Modal.Footer>
      </Modal>
      <Button appearance="primary" onClick={setShowAddModal}>추가</Button>
      <DndProvider backend={HTML5Backend}>
        <Table autoHeight data={articles} loading={loading} rowHeight={64}>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.Cell dataKey="name" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>설명</Table.HeaderCell>
            <Table.Cell dataKey="description" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>등록자</Table.HeaderCell>
            <Table.Cell dataKey="admin_id" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>등록일자</Table.HeaderCell>
            <Table.Cell>
              {
                (rowData) => dayjs(rowData.created_at).format('YYYY년 MM월 DD일')
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.Cell>
            {
                (rowData) => (
                  <>
                    <Button
                      style={{ marginLeft: 8 }}
                      appearance="default"
                      color="red"
                      onClick={() => removeArticle(rowData.id)}
                    >
                      삭제
                    </Button>
                  </>
                )
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>정렬</Table.HeaderCell>
            <DraggableCell onDrag={handleDragRow}>
              <Icon icon="arrows"/>
            </DraggableCell>
          </Table.Column>
        </Table>
      </DndProvider>
    </Page>
  );
};

export default LivingTv;